import React from 'react'
import {useTheme} from '../components/Theming'
import Container from '../components/Container'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'
import { css } from '@emotion/core'

const Foerderer = () => {
    const theme = useTheme()
    const data = useStaticQuery(graphql`
    query {
        site {
          ...site
          siteMetadata {
            title
          }
        }
    }
    `)
    return(
        <Layout site={data.site}>
        <Container  css={css`
        display:flex;
        flex-direction:column;
        justify-content:space-between;
          section img{
            object-width:cover;
            height:auto;
            max-width:200px;
            padding:50px 40px 60px 0;
            
          } 
          section img:nth-of-type(2){
            object-width:cover;
            height:auto;
            max-width:400px;
          
          } 
          p {
            font-size:1.1rem;
          }
          @media (max-width: 500px) {
            p {
              font-size:.9rem;
            }
            img {padding:60px 0;margin:0;}
            img:nth-of-type(2){
              margin:0;
              padding:0px;
              width:250px;
            } 
          }
        `}>
          <h1 style={{fontSize:theme.fontsizes.middle}}>
             Förderer von Markt 1
          </h1>
       <p>Folgende Vereine und Institutionen unterstützen unsere Arbeit:<br/>

<strong>Zweckverband Mittelrhein e.V.</strong> <br/>  
<strong>LandKultur / Rheinland-Pfalz</strong> </p>
     

 <section style={{background: 'lightgrey',padding:20}}>
 <img  src="/images/zweckverband.png" alt="Logo Zweckverband" arial-label="Logo Zweckverband"/>
  
 <img  src="/images/landkultur.png" alt="Logo Landkultur" arial-label="Logo Landkultur"/>
 </section>
      <div style={{paddingTop:20}}>

            <p>55422 Bacharach, 
          Marktstraße 3<br/>
          <i>Ansprechpartnerin:</i><br/>
          Katrin Gloggengießer<br/>
          Email: kontakt@markt1-bacharach.de
         </p>
 </div>
        </Container>
        </Layout>

    )
}

export default Foerderer

